import { GlobalStyles, Stack, ThemeProvider, Typography } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { useMeQuery } from "../../generated/graphql";
import Notification from "../../ui/Notification";
import useBgColor from "../../utils/useBgColor";
import CommonHead from "./CommonHead";
import MuiTheme from "./theme";

const BaseLayout: FC<
  PropsWithChildren<{ bgColor?: string; intercom?: boolean }>
> = ({ children, bgColor, intercom = true }) => {
  const color = useBgColor();

  return (
    <>
      <CommonHead />
      <GlobalStyles
        //@ts-ignore()
        styles={(props: any) => ({
          "*": {
            boxSizing: "border-box",
            padding: 0,
            margin: 0,
          },
          body: {
            backgroundColor: bgColor || color,
            textRendering: "optimizeLegibility",
            " -webkit-font-smoothing": "antialiased",
            overflowX: "hidden",
            overflowY: "scroll",
          },
          ".intercom-lightweight-app": {
            display: intercom ? "initial" : "none",
            zIndex: `1209 !important`,
          },
        })}
      />

      <ThemeProvider theme={MuiTheme}>{children}</ThemeProvider>
    </>
  );
};

export default BaseLayout;
