import { useRouter } from "next/router";
import React from "react";

const useBgColor = () => {
  const { asPath } = useRouter();
  let isMatch = false;
  const withWhiteColor = ["pricing"];

  withWhiteColor.forEach((l) => {
    if (isMatch) return;
    isMatch = !!asPath.match(l);
  });

  return isMatch ? "#ffffff" : "#F5F5F5";
};

export default useBgColor;
