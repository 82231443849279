import { green, red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import { Box } from "@mui/material";

const Poppins = ["Poppins", "sans-serif"].join(",");

const BASE_THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ms: 370,
      sm: 600,
      md: 900,
      mg: 1024,
      lg: 1248,
      xl: 1536,
    },
  },
});

const BASE_BUTTON: any = (t: typeof BASE_THEME) => ({
  borderRadius: "100px",
  color: "#fff",
  textTransform: "none",
  padding: "16px 24px",
  fontFamily: Poppins,
  fontSize: 16,
  minWidth: "60px",
  [t.breakpoints.down("sm")]: {
    padding: "16px 14px",
  },
  [t.breakpoints.down(360)]: {
    fontSize: 12,
  },
});

const MuiTheme = createTheme(BASE_THEME, {
  components: {
    defaulProps: {
      variant: "primary",
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(0,0,0,0.26)",
            backgroundColor: "rgba(0,0,0,0.26)",
          },
        },
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            backgroundColor: "#0F75FB",
            "&:hover": {
              backgroundColor: "#0F64FB",
            },
          },
        },
        {
          props: { variant: "primary", size: "small" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            padding: "6px 24px",
            backgroundColor: "#0F75FB",
            border: "2px solid #0F75FB",
            fontSize: 14,
            [BASE_THEME.breakpoints.down("sm")]: {
              padding: "6px 24px",
            },
            "&:hover": {
              backgroundColor: "#0F64FB",
              border: "2px solid #0F64FB",
            },
          },
        },
        {
          props: { variant: "close" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            backgroundColor: "#F7F7F7",
            border: "none",
            color: "#0F75FB",
            fontSize: 16,
            [BASE_THEME.breakpoints.down("sm")]: {
              padding: "16px 14px",
            },
            "&:hover": {
              backgroundColor: "#0F75FB",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "success", size: "small" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            padding: "6px 24px",
            backgroundColor: "#66B045",
            border: "2px solid #66B045",
            color: "#0F75FB",
            fontSize: 14,
            [BASE_THEME.breakpoints.down("sm")]: {
              padding: "6px 24px",
            },
            "&:hover": {
              backgroundColor: "#66B045",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            padding: "14px 24px",
            backgroundColor: "#fff",
            color: "#0F75FB",
            border: "2px solid #0F75FB",
            "&:hover": {
              backgroundColor: "#0F75FB",
              color: "#fff",
              border: "2px solid #0F75FB",
            },
            [BASE_THEME.breakpoints.down("sm")]: {
              padding: "14px 14px",
            },
          },
        },
        {
          props: { variant: "outlined", size: "small" },
          style: {
            ...BASE_BUTTON(BASE_THEME),
            padding: "6px 24px",
            backgroundColor: "#fff",
            color: "#0F75FB",
            fontSize: 14,
            [BASE_THEME.breakpoints.down("sm")]: {
              padding: "6px 24px",
            },
            border: "2px solid #0F75FB",
            "&:hover": {
              backgroundColor: "#0F75FB",
              color: "#fff",
              border: "2px solid #0F75FB",
            },
          },
        },
        {
          props: { variant: "link" },
          style: {
            textTransform: "none",
            fontFamily: "Poppins",
            color: "#0F75FB",
            padding: "16px 0px",
            fontSize: 16,
            fontWeight: 500,
            width: "fit-content",

            "&:hover": {
              color: "#0F64FB",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "link", color: "warning" },
          style: {
            textTransform: "none",
            fontFamily: "Poppins",
            color: "#DE3D62",
            fontSize: 16,
            padding: "16px 0px",
            fontWeight: 500,
            width: "fit-content",

            "&:hover": {
              color: "#0F64FB",
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        color: "primary",
        fullWidth: true,
        InputProps: { disableUnderline: true },
        sx: {
          "& .MuiFormControl-root": {
            borderRadius: "15px",
          },
          "& .MuiInputBase-input": {
            pt: "24px",
          },
          "& .MuiInputBase-root": {
            "&:hover": {
              backgroundColor: "#F7F7F7",
            },
            backgroundColor: "#F7F7F7",
            borderRadius: "15px",
          },
          "& .MuiFormLabel-root": {
            fontSize: "14px",
            top: "2px",
          },

          "& .MuiFormHelperText-root": {
            borderRadius: "10px",
            backgroundColor: "#de3d6247",
            padding: "8px",
            marginBottom: "12px",
            marginTop: "12px",
            position: "relative",
            fontSize: 13,
            color: "rgb(247, 25, 25)",
            fontFamily: Poppins,
            fontWeight: 600,
            mt: "12px",
            mx: 0,

            "&::after": {
              width: "0px",
              height: "0px",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: "8px solid  #de3d6247",
              content: '""',
              position: "absolute",
              top: "-8px",
              left: "1rem",
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "filled", color: "primary" },
          style: {
            "& .MuiInputBase-root": {
              "&.Mui-focused": {
                outline: "1px solid #0F75FB",
              },
            },
            "& .MuiFormLabel-root": {
              color: "#515151",
            },
            "& .MuiInputBase-input": {
              color: "#04080F",
            },
          },
        },
        {
          props: { variant: "filled", color: "error" },
          style: {
            "& .MuiInputBase-root": {
              outline: "1px solid #DE3D62",
              "&.Mui-focused": {
                outline: "1px solid #DE3D62",
              },
            },
            "& .MuiFormLabel-root": {
              color: "#DE3D62",
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <DoneIcon fontSize="medium" />,
        icon: (
          <Box
            sx={{
              border: "1px solid #E6E6E7",
              width: 24,
              height: 24,
              borderRadius: "6px",
            }}
          />
        ),
        sx: {
          "& .MuiSvgIcon-root": {},
          "&.Mui-checked": {
            color: "#fff",
            "& .MuiSvgIcon-root": {
              backgroundColor: "#0F75FB",
              borderRadius: "6px",
              outline: "1px solid #0F75FB",
              border: "2px solid #fff",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        sx: {
          "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "21px",
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          borderRadius: "15px",
          fontWeight: 700,
          backgroundColor: "#fff",
          alignItems: "center",
          color: "#000",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
          "& .MuiAlert-icon": {
            color: "inherit",
          },
          "& .MuiAlert-action": {
            padding: "0px 0 0 16px",
          },
        },
        outlinedSuccess: {
          borderColor: "#66B045",
          color: "#66B045",
        },
        outlinedError: {
          borderColor: "#DE3D62",
          color: "#DE3D62",
        },
        outlinedWarning: {
          borderColor: "#66B045",
          color: "#66B045",
        },
        outlinedInfo: {
          borderColor: "#8060FB",
          color: "#8060FB",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0F75FB",
      hover: "#1E74E5",
    },
    secondary: {
      main: "#04080F",
    },
    info: {
      main: "#FB8932",
    },
    error: {
      main: "#DE3D62",
      hover: "#C83658",
    },
    success: {
      main: "#66B045",
    },
    purple: {
      main: "#8060FB",
    },
    salmon: {
      main: "#E75476",
    },
    system: {
      main: "#515151",
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography: {
    color: "#04080F",
    fontFamily: Poppins,
    h1: {
      color: "#04080F",
      fontSize: "3rem",
      fontFamily: Poppins,
      fontWeight: 700,
      lineHeight: "63px",
      [BASE_THEME.breakpoints.down("md")]: {
        fontSize: "2.5rem",
        lineHeight: "52px",
      },
      [BASE_THEME.breakpoints.down("sm")]: {
        fontSize: "2rem",
        lineHeight: "42px",
      },
      [BASE_THEME.breakpoints.down(330)]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontFamily: Poppins,
      color: "#04080F",
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: "52px",
      [BASE_THEME.breakpoints.down("sm")]: {
        fontSize: "2rem",
        lineHeight: "42px",
      },
    },
    h3: {
      fontFamily: Poppins,
      color: "#04080F",
      fontSize: "2rem",
      fontWeight: 600,
    },
    h4: {
      fontFamily: Poppins,
      color: "#0F75FB",
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    body1: {
      fontFamily: Poppins,
      color: "#04080F",
      lineHeight: 1.9,
    },
    body2: {
      fontFamily: Poppins,
      color: "#04080F",
      lineHeight: 1.9,
      fontSize: "1.5rem",
      fontWeight: 600,
    },
  },
});

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    link: true;
    navigationLink: true;
    close: true;
    success: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    purple: Palette["primary"];
    salmon: Palette["primary"];
    system: Palette["primary"];
  }
  interface PaletteOptions {
    purple: PaletteOptions["primary"];
    salmon: PaletteOptions["primary"];
    system: PaletteOptions["primary"];
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    ms: true;
    mg: true;
  }
}

export default MuiTheme;
